import React from 'react';
import {
  Grid,
  ListItem,
  Typography,
  IconButton,
  Button,
  Divider,
  OutlinedInput,
  InputAdornment
} from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import * as MuiIcons from '@material-ui/icons';
import { formatCurrency } from '~/utils/format';

const RegraItem = ({
  produto,
  regra,
  editarQuantidade,
  quantidadeValor,
  handleQuantityChange,
  handleQuantityBlur,
  handleEnterKeyPress,
  handleEditQuantidade,
  handleProdutoClick,
  classes
}) => {
  return (
    <React.Fragment>
      <Grid container>
        <ListItem disabled={produto?.desativado}>
          <Grid item md={1}>
            <Typography>{produto?.codigo}</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography noWrap>{produto?.descricao}</Typography>
          </Grid>
          <Grid item md={1}>
            <Typography noWrap>{produto?.ean}</Typography>
          </Grid>
          <Grid item md={1} style={{ display: 'flex', justifyContent: 'center' }}></Grid>
          <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography noWrap>{regra?.descricao}</Typography>
          </Grid>
          <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography>{formatCurrency(regra?.precoVenda)}</Typography>
          </Grid>
          <Grid item md={1} className={classes.quantityContainer}>
            {editarQuantidade === regra ? (
              <OutlinedInput
                className={classes.quantityInput}
                value={quantidadeValor}
                onChange={e => handleQuantityChange(e)}
                onBlur={handleQuantityBlur}
                autoFocus
                onKeyDown={ev => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    ev.stopPropagation()
                    handleEnterKeyPress();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={e => {
                        e.stopPropagation();
                        handleQuantityBlur();
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            ) : (
              <>
                <Typography>{regra?.quantidadeMinima.toFixed(2).replace('.', ',')}</Typography>
                <IconButton onClick={e => handleEditQuantidade(e, regra)} size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </Grid>
          <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography>{formatCurrency(regra?.valorBrutoQntdMinima)}</Typography>
          </Grid>
          <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography>
            {regra.quantidadeMinima >= regra.quantidadeMinimaOriginal
              ? regra.porcentagemDescontoRegra != null
                ? formatCurrency(regra?.valorBrutoQntdMinima * regra.porcentagemDescontoRegra / 100)
                : formatCurrency(regra.valorDescontoRegra)
              : 'R$ 0,00'}
            </Typography>
          </Grid>
          <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography>{formatCurrency(regra?.valorTotalQntdMinima)}</Typography>
          </Grid>
          <Button
            variant="contained"
            className="button-success"
            onClick={event => {
              if (produto?.desativado) {
                return;
              }
              event.stopPropagation();
              const produtoSelecionado = {
                ...produto,
                produtoSelecionadoRegra: regra
              };
              handleProdutoClick(produtoSelecionado);
            }}
          >
            <MuiIcons.CheckCircle />
            <span style={{ marginLeft: 8 }}>Selecionar</span>
          </Button>
        </ListItem>
      </Grid>
      <Divider />
    </React.Fragment>
  );
};

export default RegraItem;
